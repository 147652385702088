import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect : '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/news/index.vue'),
    meta: {
      name: '新闻公告',
      // requiresAuth:true
    },
  },
  {
    path: '/news-detail',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '@/views/news/news-detail/index.vue'),
    meta: {
      name: '新闻公告详情',
      // requiresAuth:true
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '@/views/register/index.vue')
  },
  {
    path: '/rental-listings',
    name: 'rental-listings',
    component: () => import(/* webpackChunkName: "about" */ '@/views/rental-listings/index.vue'),
    meta: {
      name: '出租房源',
      // requiresAuth:true 
    }
  },
  {
    path: '/rental-listings-detail',
    name: 'rental-listings-detail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/rental-listings/list/detail.vue'),
    meta: {
      name: '房源详情',
      // requiresAuth:true 
    }
  },
  {
    path: '/personal-center',
    name: 'personal-center',
    component: () => import(/* webpackChunkName: "about" */ '@/views/personal-center/index.vue')
  },
  {
    path: '/individual-application',
    name: 'individual-application',
    component: () => import(/* webpackChunkName: "about" */ '@/views/individual-application/index.vue'),
    meta: {
      name: '个人申请列表',
      // requiresAuth:true 
    }
  },
  {
    path: '/add-info',
    name: 'add-info',
    component: () => import(/* webpackChunkName: "about" */ '@/views/individual-application/components/addInfo.vue'),
    meta: {
      name: '保租房申请',
      // requiresAuth:true 
    }
  },
  {
    path: '/detail-info',
    name: 'detail-info',
    component: () => import(/* webpackChunkName: "about" */ '@/views/individual-application/components/detail.vue'),
    meta: {
      name: '保租房申请进度查询',
      // requiresAuth:true 
    }
  },
  {
    path: '/project-application',
    name: 'project-application',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/index.vue'),
    meta: {
      name: '项目申请列表',
      // requiresAuth:true 
    }
  },
  {
    path: '/add-project-info',
    name: 'add-project-info',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/addProjectInfo.vue'),
    meta: {
      name: '项目申请',
      // requiresAuth:true 
    }
  },
  {
    path: '/project-cancellation',
    name: 'project-cancellation',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/projectCancellation.vue'),
    meta: {
      name: '项目注销',
      // requiresAuth:true 
    }
  },
  {
    path: '/detail-project-info',
    name: 'detail-project-info',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/detail.vue'),
    meta: {
      name: '项目申请进度查询',
      // requiresAuth:true 
    }
  },
  {
    path: '/addinfo-success',
    name: 'addinfo-success',
    component: () => import(/* webpackChunkName: "about" */ '@/views/individual-application/components/addInfoSuccess.vue'),
    meta: {
      name: '项目申请成功',
      // requiresAuth:true 
    }
  },
  {
    path: '/unfreez-funds',
    name: 'unfreez-funds',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/unfreezfunds.vue'),
    meta: {
      name: '资金解冻',
      // requiresAuth:true 
    }
  },
  {
    path: '/unfreez-list',
    name: 'unfreez-list',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/unfreezeList.vue'),
    meta: {
      name: '资金解冻列表',
      // requiresAuth:true 
    }
  },
  {
    path: '/add-unfreeze',
    name: 'add-unfreeze',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/addUnfreeze.vue'),
    meta: {
      name: '新增解冻申请',
      // requiresAuth:true 
    }
  },
  {
    path: '/unfreeze-detail',
    name: 'unfreeze-detail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/project-application/components/unfreezeDetail.vue'),
    meta: {
      name: '解冻申请进度查询',
      // requiresAuth:true 
    }
  }
]

const router = new VueRouter({
  routes
})

// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
  // 通过requiresAuth判断当前路由导航是否需要登录
  console.log(to.matched,"token",to.matched.some(record => record.meta.requiresAuth))
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let token = store.state.user.token
    // console.log("token",token)
    // 若需要登录访问，检查是否为登录状态
    if (!token) {
      // next(
      // path: '/',
      // )
      next()
    } else {
      next()
    }
  } else {
    next() // 确保一定要调用 next()
  }
})

export default router
