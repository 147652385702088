<template>
  <el-dialog
    class="login-dialog"
    title=""
    :visible.sync="visvible"
    width="490px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="login-dialog-main">
      <div class="login-content">
        <div class="login-content_tabs">
          <span
            v-for="tab in tabs"
            :key="tab.idx"
            :class="`login-content_tabs-item  ${
              activeTab === tab.idx ? 'active' : ''
            }`"
            @click="changeTab(tab.idx)"
            >{{ tab.name }}</span
          >
        </div>
      </div>

      <el-form
        :model="loginForm"
        :rules="formRules"
        ref="loginForms"
        label-width="1px"
        class="login-form"
      >
        <el-form-item prop="phone" label="">
          <el-input
            maxlength="11"
            placeholder="请输入手机号码"
            v-model="loginForm.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="pass" class="password-box">
          <el-input
            :type="inputType ? 'password' : 'text'"
            v-model="loginForm.pass"
            placeholder="请输入密码"
          >
            <i
              v-if="!inputType"
              class="el-icon-view el-input__icon"
              slot="suffix"
              @click="handleIconClick"
            >
            </i>
          </el-input>
          <img
            v-if="inputType"
            class="password-img"
            :src="require('@/assets/password.png')"
            @click="handleIconClick"
          />
        </el-form-item>
      </el-form>

      <div class="forget blue" @click="forgetDialog()">忘记密码</div>
      <div class="agreeon">
        <el-checkbox v-model="checkedAgree"></el-checkbox
        ><span class="agreeon-box"
          >登录即同意 <span class="blue" @click="jumpCommitmentLetter(2)">用户协议</span> 和
          <span class="blue" @click="jumpCommitmentLetter(3)">隐私政策</span></span
        >
      </div>
      <div class="footer">
        <div class="footer-btn" @click="signIn">登录</div>
      </div>
    </div>
    <div class="go-sign">
      <span>没有账号？<span class="blue" style="cursor: pointer;" @click="goLoagin">立即注册</span></span>
    </div>
  </el-dialog>
</template>
<script>
import { homeUserDoLogin } from "@/api/login";
import { awaitWrap } from "@/util/patch";
import CommitmentLetter from "@/components/commitment-letter/index.vue";
import { mapMutations, mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";
const { mapMutations: mapUserMutations } = createNamespacedHelpers("user");
export default {
  name: "Login",
  props: {
    dialogVisvible: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      inputType: true,
      visvible: false,
      activeTab: 1, //1：个人登录  2：企业登录
      tabs: [
        { name: "个人登录", idx: 1 },
        { name: "企业登录", idx: 2 },
      ],
      checkedAgree: false,
      loginForm: {
        phone: "",
        pass: "",
      },
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入正确的手机号码",
            trigger: ["blur"],
          },
          {
            validator: this.checkoutPhone,
            trigger: ["change"],
          },
        ],
      },
    };
  },
  created() {
    this.visvible = this.dialogVisvible;
  },
  computed: {
    ...mapState(["isShowForgetPassDialog"]),
  },
  methods: {
    ...mapMutations(["setIsShowLoginDialog", "setForgetPassDialog"]),
    ...mapUserMutations(["setToken", "setUserInfo"]),
    checkoutPhone(rules, value, cb) {
      const regPhone = /^1([3456789]\d{0,9})$/;
      if (regPhone.test(value)) {
        return cb();
      }
      cb(new Error("请输入正确的手机号码"));
    },
    changeTab(val) {
      this.activeTab = val;
      this.resetForm();
    },
    handleIconClick() {
      this.inputType = !this.inputType;
    },
    resetForm() {
      this.$refs.loginForms.resetFields();
    },
    forgetDialog() {
      this.$emit("forget", this.activeTab);
    },
    goLoagin() {
      this.setIsShowLoginDialog(false);
      this.$router.push({
        path: "/register",
      });
    },
    jumpCommitmentLetter(checkType) {
      let title =
          checkType === 1
              ? "用户承诺书"
              : checkType === 2
                  ? "用户协议"
                  : "隐私政策";
      this.$alert(<CommitmentLetter type={checkType} />, title, {
        dangerouslyUseHTMLString: true,
        showCancelButton: false, //是否显示取消按钮
        showConfirmButton: false, //是否显示确定按钮
        customClass: "letter-dialog-width",
      }).catch((e) => {});
    },
    signIn() {
      this.$refs.loginForms.validate(async (valid) => {
        if (!this.checkedAgree) {
          this.$message({ message: "请先勾选协议", type: "warning" });
          return;
        }
        if (valid) {
          if (this.loginForm.phone.length < 11) {
            this.$message({ message: "请输入正确的手机号码", type: "warning" });
            return false;
          }
          let [err, data] = await awaitWrap(
            homeUserDoLogin({
              phone: this.loginForm.phone,
              password: this.loginForm.pass,
              userType: this.activeTab,
            })
          );
          if (!err) {
            this.setToken(data.hantangToken);
            this.setUserInfo(data);
            this.setIsShowLoginDialog(false);
            // window.location.assign(window.location.href);
            if (this.$route.path !== '/home') {
              this.$router.push({
                path: "/home",
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login-content {
  //width: 490px;
  //padding: 0 20px;
  &_tabs {
    font-weight: 400;
    text-align: left;
    font-size: 24px;
    color: #7e878e;
  }
  &_tabs-item {
    padding-bottom: 6px;
    margin-right: 40px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    &:last-child {
      padding-right: 0;
    }
  }
}
.login-dialog-main {
  padding: 0 20px 35px 20px;
}
.active {
  color: #1764ce;
  border-bottom: 3px solid #1764ce;
}
.login-form {
  padding-top: 60px;
}
.forget {
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}
.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0 40px;
  .footer-btn {
    margin: 0 auto;
    width: 405px;
    background: #1764ce;
    border-radius: 4px;
    font-size:16px;
    color: #fff;
    padding: 14px 0;
    cursor: pointer;
  }
}
.agreeon {
  padding-top: 22px;
}
.go-sign {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #7e878e;
  background: #f7f8ff;
}
.blue {
  color: #1764ce;
}
.agreeon-box {
  margin-left: 10px;
  cursor: pointer;
}
.password-box {
  position: relative;
}
.password-img {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 12px;
  top: 14px;
}
</style>
<style  lang="scss">
.login-dialog {
  font-size: 16px;
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 30px;
  }
}

.letter-dialog-width {
  width: 1000px;
}
</style>
