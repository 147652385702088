import request from '@/util/request';

// 用户注册接口
export function userRegister(params) {
    return request.post('/v3/home/hantang/user/register', params);
}
// 获取验证码接口
export function smsSendAudit(params) {
    return request.request({
        baseURL: `web/api/v3/hantang/sms/${params.phone}/QFT1000009`,
        method: 'get'
    });
}
// 获取签名图片
export function getPolicySign(params) {
    return request.post('/hantang/file/sts/policy-sign', params)
}

// 获取签名PDF
export function getPdfPolicySign(params) {
  return request.get('/hantang/file/sts/pdf/signature', {params})
}