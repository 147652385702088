
import { mapStateArr,formatMoney } from '@/util/patch';
export default {
  install(Vue) {
    /** 状态映射 */
    Vue.filter('mapStateArr', mapStateArr);
    //业绩报表子模块 数据过滤 返回：物业地址+幢+单元+门牌号+房间号
    Vue.filter('propertyAddress', function(row) {
      const { building, unitName, roomNumber } = {
        building: row.building ? row.building + '幢' : '',
        unitName: row.unitName ? row.unitName + '单元' : '',
        roomNumber: row.roomNumber ? '【' + row.roomNumber + '】' : ''
      };
      return row.propertyAdrrStr + building + unitName + row.doorNumber + roomNumber;
    });
    Vue.filter('twoDecimal', function(row) {
      return Number(row).toFixed(2);
    });
    Vue.filter('formatMoney', formatMoney);
  }
};
