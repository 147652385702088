import axios from 'axios';
import { Message } from 'element-ui';
import { isPlainObject } from 'lodash';
import { BASE_URL } from '@/common/constants';
import { StatefulError } from '@/common/errors';
import { awaitWrap } from '@/util/patch';
import store from '@/store';

const service = axios.create({
  baseURL: BASE_URL,
  timeout: 300000,
  withCredentials: true
});

let pending = []; // 声明一个数组用于存储每个请求的取消函数和axios标识
// let CancelToken = axios.CancelToken;
let removePending = config => {
  for (let i in pending) {
    if (pending[i].url === config.url) {
      //如果参数里面带业务类型 需要判断业务类型是否一样 才执行移除操作
      if (pending[i].params && pending[i].params.businessType) {
        if (pending[i].params.businessType === config.params.businessType) {
          pending[i].f();
          pending.splice(i, 1);
        }
      } else {
        // 在当前请求在数组中存在时执行取消函数
        pending[i].f(); // 执行取消操作
        pending.splice(i, 1); // 把pending记录删掉
      }
    }
  }
};

// 暂时模拟的cookie 自行更改
service.interceptors.request.use(
  config => {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    config.headers['terminal-mode'] = 'PC';
    config.headers['hantang-token '] = store.state.user.token;
    removePending(config); // 在一个axios发送前执行一下判定操作，在removePending中执行取消操作
    //智能硬件接口url位置不一样需要判断
    if (config.operationCode) {
      // config.headers['operation-code'] = config.operationCode;
    }
    if (config.Timeout) {
      config.timeout = config.Timeout;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 * 在返回失败的情况下都会自动提示
 */
service.interceptors.response.use(
  response => {
    removePending(response.config);
    const myResponse = response.data;
    if (!checkMyResponseValidity(myResponse)) {
      // 如果myResponse里不满足完全有code、data、message这三个属性，则返回response.data自行处理
      console.error(`【${response.config.url}】此接口不满足完全有code、data、message这三个属性`);
      return myResponse;
    }
    if (String(myResponse.code) === '200') {

      let data;
      if (Array.isArray(myResponse.data)) {
        data = {
          list: myResponse.data,
          total: myResponse.count
        };
      } else if (isPlainObject(myResponse.data)) {
        data = myResponse.data;
        data.$total = myResponse.count;
      } else {
        data = {
          value: myResponse.data,
          $total: myResponse.count
        };
      }
      Object.defineProperty(data, '__message__', {
        value: myResponse.message || myResponse.msg,
        enumerable: false,
        writable: false,
        configurable: false
      });
      return data;
    } else if (['10001', '402','405'].some(code => String(myResponse.code) === code)) {
      // Cookie失效
      store.commit('setToken', '');
    } else if (
      myResponse.code === '100001' ||
      myResponse.code === '100002' ||
      myResponse.code === '100005' ||
      myResponse.code === '100007'
    ) {
      return Promise.resolve(myResponse);
    } 
    return Promise.reject(new StatefulError(myResponse.code, myResponse.message));
  },
  error => {
    if (axios.isCancel(error)) {
      return;
    }
    return Promise.reject(error);
  }
);


function checkMyResponseValidity(myResponse) {
  const myResponsePropNameSet = new Set(Object.getOwnPropertyNames(myResponse));
  return ['code', 'data', 'message'].every(wantedPropName =>
    myResponsePropNameSet.has(wantedPropName)
  );
}

/**
 * 改造后的request可以传入 notShowError 和 showSuccess 两个参数，前者确定在请求错误情况下是否不弹框（即默认弹框），后者确定请
 * 求成功情况下是否弹框（即默认不弹框）。
 */
async function handleRequest(requestPromise, config = {}) {
  const [err, result] = await awaitWrap(requestPromise);
  if (err) {
    if (!config.notShowError) {
      Message.error(err.message);
    }
    throw err;
  } else {
    if (config.showSuccess) {
      Message.success(result.__message__);
    }
    return result;
  }
}

export default {
  request: config => handleRequest(service.request(config), config),
  get: (url, config) => handleRequest(service.get(url, config), config),
  delete: (url, config) => handleRequest(service.delete(url, config), config),
  post: (url, data, config) => handleRequest(service.post(url, data, config), config),
  put: (url, data, config) => handleRequest(service.put(url, data, config), config),
  patch: (url, data, config) => handleRequest(service.patch(url, data, config), config)
};

export function flatObjectToForm(flatObject) {
  const formData = new FormData();
  Object.keys(flatObject).forEach(key => {
    formData.append(key, flatObject[key]);
  });
  return formData;
}
