<template>
  <el-dialog
    class="phone-dialog"
    title=""
    :visible.sync="visvible"
    width="490px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="phone-dialog-main">
      <div class="phone-content">电话预约</div>
      <div class="phone-content_box">
        <div class="phone-content_item">
          <span class="label">运营单位: </span> {{ htCompanyName }}
        </div>
        <div class="phone-content_item">
          <span class="label">联系方式: </span> {{ phone }}
        </div>
        <div class="phone-content_item">
          <span class="label">联系地址: </span> {{ htCompanyAddr }}
        </div>
      </div>
      <div class="footer">
        <div class="footer-btn" v-if="!userInfo">
          登录后可查看完整信息
          <span
            class="blue"
            @click="
              setIsShowLoginDialog(true);
              $emit('close');
              visvible = false;
            "
            >去登录</span
          >
        </div>
        <div v-else class="footer-btn">可拨打电话联系哦！</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { homeRoomListPhone } from "@/api/home";
import { awaitWrap } from "@/util/patch";
import { mapMutations, mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapUserState } = createNamespacedHelpers("user");
export default {
  name: "Phone",
  props: {
    dialogVisvible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      visvible: false,
      phone: "",
      htCompanyName: "",
      htCompanyAddr: "",
    };
  },
  computed: {
    ...mapState(["isShowLoginDialog"]),
    ...mapUserState(["token", "userInfo"]),
  },
  created() {
    this.visvible = this.dialogVisvible;
    this.getHomeRoomListPhone();
  },
  methods: {
    ...mapMutations(["setIsShowLoginDialog"]),
    async getHomeRoomListPhone() {
      let [err, data] = await awaitWrap(homeRoomListPhone(this.id));
      if (!err) {
        this.phone = this.token
          ? data.phone || ""
          : data.phone && data.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
        this.htCompanyName = data.htCompanyName || "";
        this.htCompanyAddr = data.htCompanyAddr || "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.phone-content {
  width: 490px;
  //padding: 0 20px;
  font-weight: 600;
  text-align: left;
  font-size: 30px;
  color: #101d37;
  margin-right: 40px;
  &_box {
    padding: 23px 0 40px;
  }
  &_item {
    font-size: 16px;
    border: 1px solid #e7e8ed;
    border-radius: 4px;
    color: #101d37;
    padding: 19px 14px 19px 20px;
    margin-top: 20px;
  }
}
.label {
  color: #909399;
}
.phone-dialog-main {
  padding: 0 20px;
}
.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0 10px;
  .footer-btn {
    margin: 0 auto;
    width: 405px;
    background: #fff8ed;
    border-radius: 4px;
    font-size: 16px;
    color: #f27100;
    padding: 14px 0;
    cursor: pointer;
  }
}
.blue {
  color: #1764ce;
  text-decoration: underline;
}
</style>
<style  lang="scss">
.phone-dialog {
  font-size: 16px;
  .el-dialog__body {
    padding: 5px 20px 30px;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 30px;
  }
}
</style>
