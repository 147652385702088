<template>
  <el-dialog
    class="edit-password-dialog"
    title=""
    :visible.sync="visvible"
    width="490px"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div class="edit-password-dialog-main">
      <div class="phone-content">修改密码</div>
      <el-form
        :model="loginForm"
        :rules="formRules"
        ref="loginForms"
        label-width="1px"
        class="login-form"
      >
        <el-form-item label="" prop="initPassword" class="password-box">
          <el-input
              :type="oldPassword ? 'password' : 'text'"
              v-model="loginForm.initPassword"
              placeholder="请输入登录密码"
          >
            <i
                v-if="!oldPassword"
                class="el-icon-view el-input__icon"
                slot="suffix"
                @click="handleOldIconClick"
            >
            </i>
          </el-input>
          <img
              v-if="oldPassword"
              class="password-img"
              :src="require('@/assets/password.png')"
              @click="handleOldIconClick"
          />
        </el-form-item>
        <el-form-item label="" prop="password" class="password-box">
          <el-input
            :type="newPassword ? 'password' : 'text'"
            v-model="loginForm.password"
            placeholder="请输入新密码"
          >
            <i
              v-if="!newPassword"
              class="el-icon-view el-input__icon"
              slot="suffix"
              @click="handleNewIconClick"
            >
            </i>
          </el-input>
          <img
            v-if="newPassword"
            class="password-img"
            :src="require('@/assets/password.png')"
            @click="handleNewIconClick"
          />
        </el-form-item>
        <el-form-item label="" prop="confirmPassword" class="password-box">
          <el-input
              :type="confirmPassword ? 'password' : 'text'"
              v-model="loginForm.confirmPassword"
              placeholder="确认新密码"
          >
            <i
                v-if="!confirmPassword"
                class="el-icon-view el-input__icon"
                slot="suffix"
                @click="handleConfirmIconClick"
            >
            </i>
          </el-input>
          <img
              v-if="confirmPassword"
              class="password-img"
              :src="require('@/assets/password.png')"
              @click="handleConfirmIconClick"
          />
        </el-form-item>
      </el-form>

      <div class="footer">
        <div class="footer-btn" @click="onSubmit">确认</div>
      </div>
    </div>
    <div class="edit-password-fiex">密码修改成功后退出系统需要重新登录</div>
  </el-dialog>
</template>
<script>
import {awaitWrap} from "@/util/patch";
import {updatePassword} from "@/api/login";
import { mapMutations, mapState } from "vuex";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapUserState } = createNamespacedHelpers("user");

export default {
  name: "EditPassword",
  props: {
    dialogVisvible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmPassword: true,
      oldPassword: true,
      newPassword: true,
      visvible: false,
      checkedAgree: false,
      loginForm: {
        initPassword: '',  // 旧密码
        password: '',  // 新密码
        confirmPassword: '' // 确认密码
      },
      formRules: {
        initPassword: [
          { required: true, message: '请输入登录密码', trigger: 'blur'}
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        confirmPassword: [
          { required: true, message: '确认新密码', trigger: 'blur'}
        ]
      },
    };
  },
  created() {
    this.visvible = this.dialogVisvible;
  },
  computed: {
    ...mapState([
      "isShowLoginDialog",
      "isShowForgetPassDialog",
      "isShowEditPassDialog",
    ]),
    ...mapUserState(["userInfo", "token"]),
  },
  methods: {
    ...mapMutations([
      "setToken",
      "setIsEditPassDialog",
    ]),
    handleOldIconClick() {
      this.oldPassword = !this.oldPassword;
    },
    handleNewIconClick() {
      this.newPassword = !this.newPassword;
    },
    handleConfirmIconClick() {
      this.confirmPassword = !this.confirmPassword;
    },
    async onSubmit() {
      this.$refs.loginForms.validate(async (valid) => {
        if (valid) {
          const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_]{8,}$/;
          if (!reg.test(this.loginForm.password)) {
            this.$message({ message: '密码至少由8位数字字母组成!', type: 'warning'})
            return;
          }
          if (this.loginForm.password !== this.loginForm.confirmPassword) {
            this.$message({ message: '确认密码与新密码不一致!', type: 'warning'})
            return;
          }

          const [err, data] = await awaitWrap(updatePassword(this.loginForm));
          if (!err) {
            this.$message({ message: '密码修改成功，请重新登录！', type: 'success'});
            this.setIsEditPassDialog(false);
            this.$nextTick( () => {
              this.setToken("");
              if (this.$route.path !== '/home') {
                this.$router.push({
                  path: "/",
                });
              }
            })
          }
        } else {
          return false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.phone-content {
  //padding: 0 20px;
  font-weight: 600;
  text-align: left;
  font-size: 30px;
  color: #101d37;
  margin-right: 40px;
}
.edit-password-dialog-main {
  padding: 0 20px;
}
.login-form {
  padding-top: 38px;
}
.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0 85px;
  .footer-btn {
    margin: 0 auto;
    width: 405px;
    background: #1764ce;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    padding: 14px 0;
    cursor: pointer;
  }
}
.password-box {
  position: relative;
}
.password-img {
  width: 12px;
  height: 12px;
  position: absolute;
  right: 12px;
  top: 14px;
}
.edit-password-fiex {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  color: #7E878E;
  text-align: center;
  background: #F7F8FF;
}
</style>
<style  lang="scss">
.edit-password-dialog {
  font-size: 16px;
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 30px;
  }
}
</style>
