<template>
  <div class="tab-list-content">
    <template v-if="listData.length > 0">
      <div
        v-for="(item, index) in listData"
        :key="index"
        @click="junpDetail(item.id)"
        class="link"
      >
        <span class="link-content" :title="item.noticeTitle"
          ><span class="round-dot" />{{ item.noticeTitle }}</span
        >
        <span class="time">{{ item.createTime }}</span>
      </div>
    </template>
    <el-empty v-else description="暂无数据"></el-empty>
  </div>
</template>
<script>
export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  // watch: {
  //   listData: {
  //     handler(newValue, oldValue) {
  //       if (newValue) {
  //         this.lists = this.listData;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    junpDetail(id) {
      this.$router.push({
        path: "/news-detail",
        query: { id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab-list-content {
  text-align: left;
  font-weight: 400;
  color: #101d37;
  line-height: 36px;
  font-size: 16px;
  padding-top: 34px;
  height: 335px;
}
.round-dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #d8d8d8;
  border-radius: 50%;
  margin-right: 13px;
}
.time {
  float: right;
  color: #909399;
}
.link {
  cursor: pointer;
  height: 36px;
  line-height: 36px;
  .link-content {
    width: 450px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>