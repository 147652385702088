<template>
  <div>
    <div class="list-content">
      <div
        v-for="item in listData"
        :key="item.idx"
        :class="`list-content_item`"
      >
        <div @click="previewFunc(item)">
          <img v-if="item.picUrl" class="exp-img" :src="item.picUrl" />
          <img
            class="exp-img"
            :src="require('@/assets/list_empty.png')"
            v-else
          />
        </div>
        <div class="room-info-box">
          <div @click="previewFunc(item)">
            <div class="money">
              ￥{{ item.pricingMoney || 0 }}<span class="unit">/月</span>
            </div>
            <div
              class="property-adrr content-overflow"
              :title="proBuildDoorNumber(item)"
            >
              {{ proBuildDoorNumber(item) }}
            </div>
            <div class="room-info">
              <span>{{ item.storeName }}</span>
              {{ item.bedRoom || 0 }}室{{ item.livingRoom || 0 }}厅{{
                item.kitchen || 0
              }}厨{{ item.bathRoom || 0 }}卫
              {{ item.insideSpace ? "·" + item.insideSpace + "m²" : "" }}
            </div>
            <div class="room-info content-overflow">
              <i class="el-icon-location-outline" />{{ item.detailAddress }}
            </div>
          </div>
          <div class="btn-box">
            <div class="btn btn_normal" @click="clickPhoneFunc(item)">
              电话预约
            </div>
            <div
              class="btn btn_primary"
              @click="checkDetailFunc(item, 'add-info')"
            >
              保租房申请
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show-more">
      <div class="btn btn_outline" @click="jumpLink">更多房源</div>
    </div>

    <Phone
      v-if="phoneVisible"
      :id="curRow.housingId"
      :dialogVisvible="phoneVisible"
      @close="() => (phoneVisible = false)"
    />
  </div>
</template>
<script>
import { homeApplyCountCheck } from "@/api/individual-application";
import { awaitWrap } from "@/util/patch";
import { createNamespacedHelpers } from "vuex";
const { mapState: mapUserState } = createNamespacedHelpers("user");
import Phone from "./phone";
export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Phone,
  },
  data() {
    return {
      phoneVisible: false,
      curRow: {},
    };
  },
  computed: {
    ...mapUserState(["userInfo"]),
    // 物业地址
    proBuildDoorNumber() {
      return function (row) {
        const { building, unitName, roomNumber } = {
          building: row.building ? row.building + "幢" : "",
          unitName: row.unitName ? row.unitName + "单元" : "",
          roomNumber: row.roomNumber ? row.roomNumber : "",
        };
        return row.propertyAdrr + building + unitName + roomNumber;
      };
    },
  },
  created() {},
  methods: {
    async clickPhoneFunc(row) {
      let [err, data] = await awaitWrap(homeApplyCountCheck());
      if (!err) {
        if (JSON.stringify(data.value) === "true") {
          this.$message({
            message: "预约已满！",
            type: "warning",
          });
          return;
        }
        this.curRow = row;
        this.phoneVisible = true;
      }
    },
    jumpLink() {
      this.$router.push({
        path: "/rental-listings",
      });
    },

    checkDetailFunc(row, url) {
      if (this.userInfo) {
        if (this.userInfo.userType === 2) {
          this.$message({
            message:
              "保租申请针对个人保租房申请开放，企业项目请前往项目进度看！",
            type: "warning",
          });
          return;
        }
        this.$router.push({
          path: "/" + url,
          query: {
            roomId: row.id,
            housingId: row.housingId,
          },
        });
      } else {
        this.$message({
          message: "请先登录！",
          type: "warning",
        });
        return;
      }
    },

    // 房源详情
    previewFunc(row) {
      console.log("详情", row);
      this.$router.push({
        path: "/rental-listings-detail",
        query: {
          id: row.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list-content {
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  padding: 36px 0 0;
  &_item {
    width: 378px;
    text-align: left;
    border: 1px solid #e7e7e7;
    margin-bottom: 26px;
    margin-right: 30px;
    border-radius: 4px;
    cursor: pointer;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.exp-img {
  width: 100%;
  height: 269px;
}
.money {
  font-size: 22px;
  font-weight: 600;
  color: #e6a23c;
  padding: 13px 0 4px;
}
.unit {
  font-size: 14px;
  font-weight: normal;
}
.property-adrr {
  font-size: 20px;
  font-weight: 600;
  color: #101d37;
}
.room-info-box {
  padding: 0 20px;
}
.room-info {
  font-size: 14px;
  font-weight: 400;
  padding-top: 4px;
  color: #909399;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.btn {
  font-weight: 400;
  font-size: 18px;
  width: 156px;
  border-radius: 2px;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
  &_normal {
    color: #6d6d6d;
    border: 1px solid #cccccc;
  }
  &_primary {
    color: #fff;
    background: #1764ce;
    border: 1px solid #1764ce;
  }
  &_outline {
    color: #1764ce;
    border: 1px solid #1764ce;
  }
}

.show-more {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
}
.content-overflow {
  width: 325px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>