<template>
  <div class="news-announcement">
    <div class="news-announcement-main">
      <div class="news-announcement-main_title">
        NEWS
        <span class="span-pos">新闻公告</span>
      </div>
      <div class="news-announcement-main_content">
        <div class="news-announcement_banner">
          <el-carousel height="413px" v-if="bannerList.length > 0">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img width="100%" height="100%" :src="item" />
            </el-carousel-item>
          </el-carousel>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <div v-if="isComplete">
          <div class="news-announcement_tabs">
            <span
              v-for="(tab, index) in tabs"
              :key="index"
              :class="`news-announcement_tabs-item  ${
                activeTab === tab.idx ? 'active' : ''
              }`"
              @click="changeTab(tab.idx)"
              >{{ tab.name }}</span
            >
          </div>
          <TabList :list-data="listData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { homeNoticeList, homeNoticeBannerList } from "@/api/home";
import { awaitWrap } from "@/util/patch";
import TabList from "./components/TabList";
export default {
  components: {
    TabList,
  },
  data() {
    return {
      isComplete: false,
      activeTab: 1,
      tabs: [
        { name: "政务公开", idx: 1 },
        { name: "政务服务", idx: 2 },
        { name: "租房结果", idx: 3 },
        { name: "项目结果", idx: 4 },
        { name: "国家政策", idx: 5 },
      ],
      bannerList: [],
      listData: [],
      parmars: {
        noticeState: 1, //通过审核的公告
        noticeType: 1, //类型性质 1、公告，2、承诺书
        pageNum: 1,
        pageSize: 9,
      },
    };
  },
  async created() {
    await this.getHomeNoticeBannerList();
    await this.getHomeNoticeList();
    this.isComplete = true;
  },
  methods: {
    async getHomeNoticeBannerList() {
      let [err, data] = await awaitWrap(homeNoticeBannerList());
      if (!err) {
        this.bannerList = [...data.list];
      }
    },
    async getHomeNoticeList() {
      let [err, data] = await awaitWrap(
        homeNoticeList({
          ...this.parmars,
          classification: this.activeTab,
        })
      );
      if (!err) {
        this.listData = [...data.list];
      }
    },
    changeTab(val) {
      this.activeTab = val;
      this.getHomeNoticeList();
    },
  },
};
</script>
<style lang="scss" scoped>
.news-announcement {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}
.news-announcement-main {
  width: 1200px;
  padding-top: 50px;

  &_title {
    position: relative;
    font-size: 90px;
    height: 124px;
    color: rgba(145, 154, 172, 0.1);
  }
  &_content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;
  }
}

.span-pos {
  color: #101d37;
  font-size: 48px;
  position: absolute;
  top: 15px;
  left: 515px;
}
.news-announcement {
  &_banner {
    width: 620px;
    height: 413px;
    margin-right: 24px;
  }
  &_tabs {
    flex: 1;
    text-align: left;
    font-size: 20px;
    border-bottom: 1px solid #e7e8ed;
    color: #101d37;
  }
  &_tabs-item {
    padding-bottom: 15px;
    margin-right: 30px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    &:last-child {
      padding-right: 0;
    }
  }
}
.active {
  border-bottom: 2px solid #1764ce;
  font-weight: 600;
  color: #1764ce;
}
</style>
<style  lang="scss" >
.el-carousel__button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 85%;
}
</style>