import request from '@/util/request';


// 房间列表查询
export function homeRoomList(params) {
  return request.get('/v3/home/hantang/room/list', {
    params
  });
}

// 房间详情查询
export function homeRoomDetail(params) {
  return request.get(`/v3/home/hantang/room/detail/${params.id}`);
}

//电话预约
export function homeRoomListPhone(id) {
  return request.get(`/v3/home/hantang/room/company/${id}`);
}

// 区域列表查询
// 上线时要改成 哈尔滨市：656 测试联调使用重庆市：2324
export function homeRoomArea() {
  return request.get(`/v3/home/hantang/room/area/2324`);
}


// 公告列表查询
export function homeNoticeList(params) {
  return request.get(`/v3/home/hantang/notice/getList`,{params});
}

// 公告详情查询
export function homeNoticeDetail(id) {
  return request.get(`/v3/home/hantang/notice/detail/${id}`);
}


// 轮播图片查询
export function homeNoticeBannerList() {
  return request.get(`/v3/home/hantang/notice/banner`);
}
//

// 获取承诺书
export function homeNoticeDetailCommitment() {
  return request.get(`/v3/home/hantang/notice/detail/commitment`);
}