import request from '@/util/request';

//登录
export function homeUserDoLogin(params) {
  return request.post(`/v3/home/hantang/user/doLogin`,params);
}
//退出登录
export function homeUserOutLogin() {
  return request.post(`/v3/home/hantang/user/logout`);
}
//修改密码
export function updatePassword(params) {
  return request.put('/v3/home/hantang/user/update/password', params);
}
//获取个人信息
export function getUserDetail(params) {
  return request.get('/v3/home/hantang/user/detail', params);
}
//修改个人信息
export function updateUserInfo(params) {
  return request.put('/v3/home/hantang/user/update/info', params);
}
//重置密码
export function forgetPassword(params) {
  return request.post('/v3/home/hantang/user/forget/password', params);
}
